<template>
    <div id="app">
        <transition-group name="move" mode="out-in">
        <ChatButton
            :key="1"
            :style="buttomStyle"
            v-show="chatLoaded && !chatOpened"
            @click-chat-balloon="changeChatVisibility"
            @remove-iara="removeIara"
        />
        <ChatScreen
            v-show="chatLoaded && chatOpened"
            :key="2"
            :style="screenStyle"
            :chat-opened="chatOpened"
            :messages="messages"
            :transferido="transferido"
            :urlIChatInventtis="urlIChatInventtis"
            :connected="connected"
            @close-chat="changeChatVisibility"
            @send-message="sendMessage"
            @transfer-to-iara="transferToIara"
        />
        </transition-group>        
    </div>
</template>

<script>
import ChatButton from "./components/ChatButton.vue";
import ChatScreen from "./components/ChatScreen.vue";
import axios from "axios";
export default 
{
    name: "App",
    components:
    {
        ChatButton,
        ChatScreen,
    },
    data(){
        
        return {
            publicPath: process.env.BASE_URL,
            neverClicked: true,
            transferido: false,
            urlIChatInventtis: '',
            chatOpened: false,
            chatLoaded: false,
            uuid: "",
            messages: [],
            device: new URL(location.href).searchParams.get("device") || "desktop",
            tela: new URL(location.href).searchParams.get("tela") || "tela",
            empreendimento: new URL(location.href).searchParams.get("empreendimento") || "ATENDIMENTO_PADRAO",
            color: new URL(location.href).searchParams.get("color") ? decodeURIComponent(new URL(location.href).searchParams.get("color")) : "#066572",
            connected: false,
        };
    },

    computed: {
        buttomStyle() 
        {
            let variables = 
            {
                "--primary_color": this.color,
            };
            if (this.device !== null && this.device === "desktop") 
            {
                variables["--bottom"] = "0px";
                variables["--right"] = "30px";
                variables["--margin"] = "5px";
            } else if (this.device !== null && this.device === "mobile") {
                variables["--right"] = "5px";
                variables["--bottom"] = "0px";
                variables["--margin"] = "0px";
            }
            return variables;
        },

        screenStyle() 
        {
            let variables = 
            {
                "--primary_color": this.color,
            };
            if (this.device !== null && this.device === "desktop") 
            {
                variables["--right"] = "30px";
                variables["--bottom"] = "5px";
                variables["--margin"] = "5px";
            } else if (this.device !== null && this.device === "mobile") {
                variables["--right"] = "0px";
                variables["--bottom"] = "0px";
                variables["--margin"] = "0px";
            }
            return variables;
        },
    },

    methods: {
        async userSendMessage(message, retry = 0){
        try 
        {
            let response = await axios.post(process.env.VUE_APP_API_HOST + '/widget', message);
            this.uuid = response.data.uuid;
            this.messages.push({
            message: response.data.message,
            from: "operador",
            });

            if(response.data && response.data.message && response.data.message.end_conversation)
            {
                setTimeout(() => {
                    this.uuid = "";
                    this.urlIChatInventtis = response.data.url;
                    this.transferido = true;
                }, 5000);
            }
        } catch (error) {
            if(retry < 3)
            {
                this.userSendMessage(message, retry + 1);
            }
        }
        },

        /** 
        *! O postMessage envia uma mensagem para quem tiver escutando com esse type nesse caso o addEventListener no widget.js ou widget.dev.js
        */
        removeIara() 
        {
            this.chatLoaded = false;
            setTimeout(() => 
            {
                window.parent.postMessage({ type: "remove-widget"}, "*");      
            },1000);
        },

        startChat()
        {
            this.connected = true;
            let emitData = {
                message: "Widget",
                uuid: this.uuid,
                empreendimento: this.empreendimento,
            };
            this.userSendMessage(emitData);
        },

        /** 
        *! Chama essa função no momento que clica no chat 
        */
        changeChatVisibility() 
        {
            if(this.neverClicked)
            {
                this.startChat();
                this.neverClicked = false;
            }
            /** 
            * * Passa o OPEN como true e a mensagem "risize" 
            */
            this.chatOpened = !this.chatOpened;
            window.parent.postMessage({ type: "resize", open: this.chatOpened }, "*");
        },

        sendMessage(message) 
        {
            let renderMessage = {
                message: {
                text: message,
                },
                from: "cliente",
            };
            this.messages.push(renderMessage);

            let emitData = {
                message: message,
                uuid: this.uuid,
                empreendimento: this.empreendimento,
            };
            this.userSendMessage(emitData);
        },

        transferToIara()
        {
            this.urlIChatInventtis = '';
            this.messages = [];
            this.uuid = '';
            this.transferido = false;
            this.startChat();
        },

        forceOpen()
        {
            this.chatLoaded = false;
            if(this.neverClicked) 
            {
                this.startChat();
                this.neverClicked = false;
            }
            this.chatOpened = true;
            this.chatLoaded = true;
            setTimeout(() => {
                window.parent.postMessage({ type: "resize", open: true, reopenIara: true }, "*");            
            }, 200);

        }
    },
    
    mounted() 
    {
        setTimeout(() => {
            this.chatLoaded = true;
        }, 1000);
    
        if (this.tela === "true") 
        {
            this.changeChatVisibility()
        }
        window.addEventListener("message", (event) => {
            if (event.data.type === 'reabrirIara') 
            {
                this.forceOpen();
            }
        }, false);
    },
};

</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");

@font-face {
  font-family: "Mark Pro Bold";
  src: url("./assets/fonts/mark pro/MARKPROBOLD.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Mark Pro Light";
  src: url("./assets/fonts/mark pro/MARKPROLIGHT.woff") format("woff");
  font-style: normal;
}
html,
body,
#app {
  font-family: "Mark Pro Bold", sans-serif;

  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.move-enter-active {
  transition: all 0.2s 0.5s ease-in-out /*cubic-bezier(0.48,-0.05, 0.49, 1.13)*/;
}
.move-leave-active {
  transition: all 0.2s ease-in-out /*cubic-bezier(0.48,-0.05, 0.49, 1.13)*/;
}
.move-enter, .move-leave-to
  /* .move-leave-active below version 2.1.8 */ {
  transform: translateY(100%);
  opacity: 0;
}

</style>
